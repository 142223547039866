// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-current-js": () => import("./../../../src/components/templates/current.js" /* webpackChunkName: "component---src-components-templates-current-js" */),
  "component---src-components-templates-event-js": () => import("./../../../src/components/templates/event.js" /* webpackChunkName: "component---src-components-templates-event-js" */),
  "component---src-components-templates-media-js": () => import("./../../../src/components/templates/media.js" /* webpackChunkName: "component---src-components-templates-media-js" */),
  "component---src-components-templates-publication-js": () => import("./../../../src/components/templates/publication.js" /* webpackChunkName: "component---src-components-templates-publication-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-associates-js": () => import("./../../../src/pages/associates.js" /* webpackChunkName: "component---src-pages-associates-js" */),
  "component---src-pages-current-js": () => import("./../../../src/pages/current.js" /* webpackChunkName: "component---src-pages-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old_index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */)
}

