import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
// import { useFlexSearch } from 'react-use-flexsearch';
import { useLunr } from 'react-lunr';
import { navigate } from 'gatsby';

const SearchInput = styled.input`
  width: 200px;
  background: rgba(255, 255, 255, 0);
  -webkit-appearance: none;
  border-radius: 0;

  border: 0;
  border-bottom: ${props =>
    (props.visible ? '2px solid rgba(255,255,255,0)' : '2px solid #000') ||
    '2px solid rgba(255,255,255,0)'};

  &:focus,
  &:active {
    outline: none;
  }

  ::-webkit-input-placeholder {
    text-align: right;
    color: ${props =>
      (props.visible ? '#000' : 'rgba(255,255,255,0)') || '#000'};
  }
  ::-moz-placeholder {
    text-align: right;
    color: ${props =>
      (props.visible ? '#000' : 'rgba(255,255,255,0)') || '#000'};
  }
  :-ms-input-placeholder {
    text-align: right;
    color: ${props =>
      (props.visible ? '#000' : 'rgba(255,255,255,0)') || '#000'};
  }
  :-moz-placeholder {
    text-align: right;
    color: ${props =>
      (props.visible ? '#000' : 'rgba(255,255,255,0)') || '#000'};
  }
`;

const Search = ({ closeMenu, isMobileSearch, location }) => {
  const data = useStaticQuery(graphql`
    {
      localSearchProducts {
        index
        store
      }
    }
  `);

  const [isSearchOpen, setSearchOpen] = useState(
    isMobileSearch === undefined ? false : true
  );

  const index = data.localSearchProducts.index;
  const store = data.localSearchProducts.store;

  const [query, setQuery] = useState('');

  const results = useLunr(query, index, JSON.parse(store));

  const [visible, setVisible] = useState(true);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setVisible(true);
    setClicked(false);
    setQuery('');
  }, [location]);

  const navigateToSearchResultsPage = event => {
    event.preventDefault();

    navigate('/search-results', {
      state: {
        searchResults: results,
        searchQuery: query,
      },
    });
  };

  return (
    <form onSubmit={navigateToSearchResultsPage}>
      <SearchInput
        name="query"
        type="text"
        value={query}
        onChange={e => setQuery(e.target.value)}
        placeholder="Search"
        className="extra-small-body-text"
        onClick={() => setVisible(false)}
        visible={visible}
        id="search"
      />
    </form>
  );
};

export default Search;
