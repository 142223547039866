import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SearchContainer from './SearchContainer';
import Media from 'react-media';
import Askeaton from '../icons/askeaton.svg';
import MainMenu from '../global/mainMenu';

const HeaderContainer = styled.header`
  width: 100vw;
  position: fixed;
  padding: 34px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 28px;
  z-index: 2000;
  height: 7rem;
  background-color: ${props => props.background};
  @media (max-width: 767px) {
    height: 4.5rem;
    padding: 20px;
    background-color: #fff !important;
    & a {
      width: 100%;
      height: 28px;
    }
  }
`;

const Logo = styled.div`
  width: auto;
  z-index: 200;
  & > a {
    color: #ff0000;
    text-decoration: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center;
    &:visited {
      color: #ff0000;
    }
    &:hover {
      transform: rotateX(180deg) translateX(-50%);
    }
    img {
      display: none;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    & > a {
      width: 100%;
      position: relative;
      left: 0;
      transform: translateX(0);
      img {
        display: block;
      }
    }
  }
`;

const MobileLogo = styled.img`
  height: 30px;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      background: 'rgba(255,255,255,0)',
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  handleScroll(event) {
    let scrollTop = window.scrollY;

    if (scrollTop >= 5) {
      this.setState({
        background: `#fff`,
      });
    } else {
      this.setState({
        background: `transparent`,
      });
    }
  }

  render(props) {
    return (
      <HeaderContainer background={this.state.background}>
        <Media query="(max-width: 767px)">
          {matches =>
            matches ? (
              <Link to={'/'}>
                <MobileLogo src={Askeaton} />
              </Link>
            ) : (
              <>
                <MainMenu
                  pathname={this.props.location.pathname}
                  menuLinks={this.props.menuLinks}
                />

                <Logo>
                  <Link to={'/'}>{this.props.siteTitle}</Link>
                </Logo>

                <SearchContainer />
              </>
            )
          }
        </Media>
      </HeaderContainer>
    );
  }
}

export default Header;

// import React from 'react';
// import { Link } from 'gatsby';
// import styled from 'styled-components';
// import SearchContainer from './SearchContainer';
// import Media from 'react-media';
// import Askeaton from '../icons/askeaton.svg';
// import MainMenu from '../global/mainMenu';

// const HeaderContainer = styled.header`
//   width: 100vw;
//   position: fixed;
//   padding: 34px 40px;

//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;

//   font-size: 28px;
//   z-index: 2000;

//   background-color: #fff;

//   @media (max-width: 767px) {
//     padding: 20px;
//     background-color: #fff !important;
//     & a {
//       width: 100%;
//       height: 28px;
//     }
//   }
// `;

// const Logo = styled.div`
//   width: auto;
//   z-index: 200;

//   & > a {
//     color: #ff0000;
//     text-decoration: none;

//     position: absolute;
//     left: 50%;
//     transform: translateX(-50%);
//     transform-origin: center;

//     &:visited {
//       color: #ff0000;
//     }

//     &:hover {
//       transform: rotateX(180deg) translateX(-50%);
//     }
//   }

//   @media (max-width: 767px) {
//     width: 100%;
//     & > a {
//       width: 100%;
//       position: relative;
//       left: 0;
//       transform: translateX(0);
//     }
//   }
// `;

// const MobileLogo = styled.img`
//   height: 30px;
// `;

// const Header = ({ location, menuLinks, siteTitle }) => {
//   return (
//     <HeaderContainer>
//       <Media query="(max-width: 767px)">
//         {matches =>
//           matches ? (
//             <Link to={'/'}>
//               <MobileLogo src={Askeaton} />
//             </Link>
//           ) : (
//             <>
//               <MainMenu pathname={location.pathname} menuLinks={menuLinks} />

//               <Logo>
//                 <Link to={'/'}>{siteTitle}</Link>
//               </Logo>

//               <SearchContainer location={location.pathname} />
//             </>
//           )
//         }
//       </Media>
//     </HeaderContainer>
//   );
// };

// export default Header;
