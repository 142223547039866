import React from 'react';
import styled from 'styled-components';
import Search from '../global/search';

const SearchOuterContainer = styled.div`
  width: 200px;

  & > p {
    margin: 0;
    float: right;
  }
`;

const SearchContainer = props => (
  <SearchOuterContainer visible={props.visible}>
    <Search
      onClick={props.onClick}
      clicked={props.clicked}
      visible={props.visible}
      modalIsOpen={props.modalIsOpen}
      closeMenu={props.closeMenu}
      location={props.location}
    />
  </SearchOuterContainer>
);

export default SearchContainer;
