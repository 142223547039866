import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Navigation = styled.nav`
  & ol {
    padding: 0;
    margin: 0;
  }
  & li {
    list-style: none;

    opacity: ${props => (props.menuActive ? '1' : '0') || '1'};
    position: relative;
    margin-top: ${props => (props.menuActive ? '0' : '-37px') || '0'};

    transition: 300ms margin-top ease;

    & a {
      color: #000;

      text-decoration: none;
      text-transform: capitalize;

      &:visited {
        color: inherit;
      }

      &:hover {
        color: #0000ff;
      }
    }
  }

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const Menu = styled.button`
  cursor: pointer;

  border: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0);
  text-transform: capitalize;

  color: ${props => props.color || '#000'};

  &:focus {
    outline: none;
  }
`;

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? null : { className: 'navlink' };
};

const PartialNavLink = props => {
  return (
    <Link getProps={isPartiallyActive} to={props.to}>
      {props.children}
    </Link>
  );
};

const DesktopMenu = ({
  menuActive,
  menuLinks,
  menuText,
  toggleMenuClose,
  toggleMenuOpen,
}) => {
  return (
    <Navigation
      menuActive={menuActive}
      onMouseOut={() => toggleMenuClose()}
      onMouseOver={() => toggleMenuOpen()}
    >
      {menuActive === true ? (
        <Menu color={'#0000ff'}>{menuText}</Menu>
      ) : (
        <Menu>{menuText}</Menu>
      )}

      <ol>
        {menuLinks.map(link => {
          return (
            <li key={link.name}>
              <PartialNavLink getProps={isPartiallyActive} to={link.link}>
                {link.name}
              </PartialNavLink>
            </li>
          );
        })}
      </ol>
    </Navigation>
  );
};

export default DesktopMenu;
