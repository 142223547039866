import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  html {
    line-height: 1.35;
  }
  a {
    &:hover {
      color: red;
      * {
        color: red;
      }
    }
  }

  body {
    font-family: 'FolioBT-Medium', 'HelveticaNeue-Medium', 'Helvetica Neue Medium', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-weight: 300;

    font-feature-settings: "ss01";

    font-size: 18px;

    font-weight: normal;
    text-shadow: 1px 1px 1px rgba(0,0,0,.004);
    text-rendering: optimizeLegibility!important;
    -webkit-font-smoothing: antialiased!important;
    -webkit-tap-highlight-color: transparent;

    @media(max-width: 500px){
      font-size: 16px;
    }
  }

  .texture-video {
    will-change: opacity;
    position: fixed;
    z-index: 200000;
    top: 0;
    opacity: 0;
    left: 0;
    bottom:0;
    right:0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    object-fit: cover;
    transition: 5s opacity ease;
    mix-blend-mode: multiply;
  };

  .texture-video.fade-in{
    opacity: 1;
    pointer-events: auto;
  }

  em {
    font-style: italic;
    font-family: 'FolioBT-Medium', 'HelveticaNeue-Medium', 'Helvetica Neue Medium', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

  }

  img {
    width: 100%;
    display: block;
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: normal;
    margin: 0;
  }

  h1, h2, h3 {
    font-size: 28px;

    @media (max-width: 767px) {
      font-size: 27px;
    }

    @media (max-width: 420px) {
      font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
    }

  }

  a{
    color: #000;
  }


  p {
    margin-bottom: 0;
  }

  a:visited{
    color: inherit;
  }

  .cloudContainer{
    opacity: 0;
    will-change: transform;
    position: fixed;
    z-index: 200000;
    top: 0;
    left: 0;
    bottom:0;
    right:0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
  };

  .cloudContainer.fade-in{
    opacity: 1;
    pointer-events: auto;
  }


  @media (max-width: 767px) {
    h1 {font-size: 27px; }
  }

  @media (max-width: 420px) {
    h1 { font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300))); }
  }


  .ReactModalPortal,
  .ReactModal__Overlay {
    z-index: 1;
  }
`;

export default GlobalStyle;
