import loadable from '@loadable/component';
import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Header from '../global/header';

import { Normalize } from 'styled-normalize';
import {
  Container,
  GridThemeProvider,
  BaseCSS,
  Row,
} from 'styled-bootstrap-grid';
import styled from 'styled-components';
import GlobalStyles from '../styles/globalStyles';
import Helmet from 'react-helmet';
import { Location } from '@reach/router';
import Media from 'react-media';
import { isMobileOnly } from 'react-device-detect';
import Div100vh from 'react-div-100vh';
import '../../../static/plyr.scss';
// Components
const Footer = loadable(() => import('../global/footer'));

// Hooks
// import { useIdle } from 'react-use';

let currentTheme;
// let currentPadding;

const gridTheme = {
  breakpoints: {
    // defaults below
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 40, // default 15
  },
  col: {
    padding: 40, // default 15
  },
  container: {
    padding: 40, // default 15
    maxWidth: {
      // defaults below
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const mobileGridTheme = {
  breakpoints: {
    // defaults below
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 20, // default 15
  },
  col: {
    padding: 20, // default 15
  },
  container: {
    padding: 20, // default 15
    maxWidth: {
      // defaults below
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const currentPadding = `
  padding-top: 105px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    padding-top: 70px;
  }
 `;

const SiteContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const RotateMessage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #fff;

  z-index: 10000;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-align: center;
`;

const Homepage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;
`;

const Layout = ({ children, props }) => {
  const [landscapeMessage, setLandscapeMessage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(768);
  // const isIdle = useIdle(5000);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
              footerLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => {
        if (typeof window !== `undefined`) {
          window.addEventListener(
            'orientationchange',
            function() {
              // Announce the new orientation number //
              if (isMobileOnly) {
                if (window.orientation === 90 || window.orientation === -90) {
                  setLandscapeMessage(true);
                } else {
                  setLandscapeMessage(false);
                }
              }
            },
            false
          );
        }

        return (
          <Location>
            {({ location }) => {
              if (typeof window !== `undefined`) {
                const htmlbg = document.documentElement;
              }

              return (
                <SiteContainer>
                  <Helmet
                    title={`Askeaton Contemporary Arts`}
                    meta={[
                      {
                        name: 'viewport',
                        content:
                          'width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1',
                      },
                    ]}
                  >
                    <script src="/plyr/plyr.polyfilled.js"></script>
                  </Helmet>
                  <Normalize />
                  <BaseCSS />
                  <GlobalStyles />
                  {landscapeMessage === true && (
                    <RotateMessage>Please rotate your device</RotateMessage>
                  )}
                  <Media
                    queries={{ medium: '(max-width: 767px)' }}
                    defaultMatches={{ medium: windowWidth === 767 }}
                    render={() => (
                      <GridThemeProvider gridTheme={mobileGridTheme}>
                        <Header
                          menuLinks={data.site.siteMetadata.menuLinks}
                          siteTitle={data.site.siteMetadata.title}
                          location={location}
                        />

                        <Container fluid css={currentPadding}>
                          <Row justifyContent="center">{children}</Row>
                        </Container>

                        <Footer
                          menuLinks={data.site.siteMetadata.menuLinks}
                          footerLinks={data.site.siteMetadata.footerLinks}
                          location={location}
                          showNewsletter={
                            location.pathname === '/publications' ? true : false
                          }
                        />
                      </GridThemeProvider>
                    )}
                  />
                  <Media
                    queries={{ medium: '(min-width: 768px)' }}
                    defaultMatches={{ medium: windowWidth === 768 }}
                    render={() => (
                      <GridThemeProvider gridTheme={gridTheme}>
                        <Header
                          menuLinks={data.site.siteMetadata.menuLinks}
                          siteTitle={data.site.siteMetadata.title}
                          location={location}
                        />

                        <Container fluid css={currentPadding}>
                          <Row justifyContent="center">{children}</Row>
                        </Container>

                        <Footer
                          menuLinks={data.site.siteMetadata.menuLinks}
                          footerLinks={data.site.siteMetadata.footerLinks}
                          location={location}
                          showNewsletter={
                            location.pathname === '/publications' ? true : false
                          }
                        />
                      </GridThemeProvider>
                    )}
                  />
                </SiteContainer>
              );
            }}
          </Location>
        );
      }}
    />
  );
};

export default Layout;
