import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

// Components
import DesktopMenu from '../navigation/desktop-menu';

class MainMenu extends React.Component {
  targetElement = null;

  constructor(props) {
    super(props);

    this.state = {
      menuActive: false,
      menuText: 'Menu',
      windowWidth: 768,
    };

    this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
    this.toggleMenuClose = this.toggleMenuClose.bind(this);
  }

  componentDidMount(props) {
    let menuText = this.props.pathname.split('/')[1];

    if (
      menuText.length > 0 &&
      menuText !== 'search-results' &&
      menuText !== 'event'
    ) {
      this.setState({ menuText: menuText });
    }

    if (typeof window !== `undefined`) {
      let windowWidthOnLoad = window.innerWidth;
      this.setState({ windowWidth: windowWidthOnLoad });
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  toggleMenuOpen = event => {
    if (this.props.searchOpen === true) {
      event.preventDefault();
    } else {
      this.setState(
        prevState => ({
          menuActive: !prevState.menuActive,
          menuText: 'Menu',
        }),
        () => {
          if (this.state.menuActive === true) {
            this.targetElement = document.querySelector('#mobile-menu');
            disableBodyScroll(this.targetElement);
          } else {
            enableBodyScroll(this.targetElement);
          }
        }
      );
    }
  };

  toggleMenuClose = event => {
    let menuText = this.props.pathname.split('/')[1];
    if (
      menuText.length > 0 &&
      menuText !== 'search-results' &&
      menuText !== 'event'
    ) {
      this.setState({ menuText: menuText });
    } else {
      this.setState({ menuText: 'Menu' });
    }

    if (this.props.searchOpen === true) {
      event.preventDefault();
    } else {
      this.setState(
        prevState => ({
          menuActive: !prevState.menuActive,
        }),
        () => {
          if (this.state.menuActive === true) {
            this.targetElement = document.querySelector('#mobile-menu');
            disableBodyScroll(this.targetElement);
          } else {
            enableBodyScroll(this.targetElement);
          }
        }
      );
    }
  };

  render(props) {
    return (
      <Media
        queries={{ medium: '(min-width: 768px)' }}
        defaultMatches={{ medium: this.state.windowWidth === 768 }}
        render={() => (
          <DesktopMenu
            menuLinks={this.props.menuLinks}
            menuActive={this.state.menuActive}
            menuText={this.state.menuText}
            toggleMenuClose={e => this.toggleMenuClose(e)}
            toggleMenuOpen={e => this.toggleMenuOpen(e)}
            location={this.props.location}
          />
        )}
      />
    );
  }
}

export default MainMenu;
